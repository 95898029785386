<template>
  <!--
    A component that executes device-status-commands
  -->
  <Portlet
    :title="$t('liveStatus')"
    class="commandDialogLiveStatus"
    icon="thermometer-half"
  >
    <template slot="buttons">
      <div>
        <span
          v-if="lastRequest"
          v-tooltip="`${ dateTime_fromNow(lastRequest) }`"
          data-toggle="tooltip"
          data-placement="top"
          class="d-none d-md-inline-block mt-3 mr-3"
        >{{ $t('lastRequest') }}: {{ lastRequest | dateTime_dateTime }}</span>
        <button 
          class="btn btn-sm btn-primary float-right" 
          @click="getCommandsStatus"
        >
          <font-awesome-icon
            class="mr-1"
            icon="sync-alt"
          />
          <span>
            {{ $t('getLiveStatus') }}
          </span>
        </button>
      </div>
    </template>

    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div class="clearfix" />
      <template v-if="commandsAvailable">
        <div
          v-for="(command, index) in commands"
          :key="`row${ index }`"
          class="row m-0 mb-4"
        >
          <div class="col-12 col-sm-3 col-md-4 p-2">
            {{ command.name }}
          </div>
          <div class="col-12 col-sm-9 col-md-8 border p-2 pl-3">
            <span
              v-if="!command.doneWaiting"
            >{{ $t('pleaseWait') }}</span>
            <LoadingPlaceholder v-else-if="command.loading" />
            <pre
              v-else-if="command.output"
            >{{ command.output }}</pre>
            <span
              v-else
              class="red"
            >{{ $t('commandCantBeExecuted') }}</span>
          </div>
        </div>
      </template>
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js'
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "CommandDialogLiveStatus",
  mixins: [
    dateTimeMixin,
    authenticationMixin
  ],
  props: {
    deviceId: {
      type: String,
      required: true
    },
    deviceIsActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      commands: null,
      loading: true,
      lastRequest: null
    }
  },
  computed: {
    commandsAvailable () {
      if (this.commands == null) {
        return false;
      }
      if (this.commands.length == false) {
        return false;
      }
      if (this.commands.length == 0) {
        return false;
      }
      return true;
    }
  },
  mounted () {
    this.loading = false;
  },
  methods: {
    getCommandsStatus () {
      this.loading = true;
      this.lastRequest = Date.now();
      this.axios.get(`/CentralDeviceManagement/GetDeviceCommands?deviceUuid=${ this.deviceId }&category=status`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.commands = response.data;
        })
        .finally(() => {
          this.executeCommands();
          this.loading = false;
          this.$nextTick(() => {
            window.$('[data-toggle="tooltip"]').tooltip();
          });
        });
    },
    async postChunk (chunks) {
        const promises = chunks.map(command => {
            this.$set(command,'doneWaiting', true);
            this.$set(command,'loading', true);
            let commandRequest = {
              'Command': command.name,
              'Args': ''
            };
            return this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ this.deviceId }`, commandRequest)
              .then((response) => {
                if (response == null) {
                  return;
                }
                if (response.data == null) {
                  return;
                }
                this.$set(command,'output', response.data.output);
              })
              .finally(() => {
                this.$set(command,'loading', false);
              });
        });
        return Promise.all(promises);
    },
    chunk (array, size) {
      if (!array.length) {
        return [];
      }
      const head = array.slice(0, size);
      const tail = array.slice(size);

      return [head, ...this.chunk(tail, size)];
    },
    async executeCommands () {
      if (this.commands == null || this.authenticationHasRole('zerolevel')) {
        return;
      }
      var chunks = this.chunk(this.commands, 3);
      let previousCall = Promise.resolve();
      
      for (let chunk of chunks) {
         previousCall = previousCall.then(() => this.postChunk(chunk));
    }
    }
  }
}
</script>

<style scoped>

pre {
  margin: 0px;
}

.reloadButton i {
  font-size: 1.5rem;
}
</style>
